var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('b-popover',{attrs:{"target":("fluxeditor-block-menu-" + _vm.uuid),"placement":"top","triggers":"focus"}},[_c('div',{staticClass:"text-center"},[(_vm.allowedBlocs.includes('text') == true)?_c('button',{staticClass:"btn btn-grey btn-icon",on:{"click":function($event){$event.preventDefault();_vm.$parent.addNewBlock(_vm.block.id, 'text', _vm.position)
          _vm.visible = false}}},[_c('font-awesome-icon',{attrs:{"icon":"paragraph"}})],1):_vm._e(),(_vm.allowedBlocs.includes('image') == true)?_c('button',{staticClass:"btn btn-grey btn-icon",on:{"click":function($event){$event.preventDefault();_vm.$parent.addNewBlock(_vm.block.id, 'image', _vm.position)
          _vm.visible = false}}},[_c('font-awesome-icon',{attrs:{"icon":"image"}})],1):_vm._e(),(_vm.allowedBlocs.includes('carousel') == true)?_c('button',{staticClass:"btn btn-grey btn-icon",on:{"click":function($event){$event.preventDefault();_vm.$parent.addNewBlock(_vm.block.id, 'carousel', _vm.position)
          _vm.visible = false}}},[_c('font-awesome-icon',{attrs:{"icon":"images"}})],1):_vm._e(),(_vm.allowedBlocs.includes('video') == true)?_c('button',{staticClass:"btn btn-grey btn-icon",on:{"click":function($event){$event.preventDefault();_vm.$parent.addNewBlock(_vm.block.id, 'video', _vm.position)
          _vm.visible = false}}},[_c('font-awesome-icon',{attrs:{"icon":"video"}})],1):_vm._e(),(_vm.allowedBlocs.includes('audio') == true)?_c('button',{staticClass:"btn btn-grey btn-icon",on:{"click":function($event){$event.preventDefault();_vm.$parent.addNewBlock(_vm.block.id, 'audio', _vm.position)
          _vm.visible = false}}},[_c('font-awesome-icon',{attrs:{"icon":"volume-up"}})],1):_vm._e(),(_vm.allowedBlocs.includes('readmore') == true)?_c('button',{staticClass:"btn btn-grey btn-icon",on:{"click":function($event){$event.preventDefault();_vm.$parent.addNewBlock(_vm.block.id, 'readmore', _vm.position)
          _vm.visible = false}}},[_c('font-awesome-icon',{attrs:{"icon":"newspaper"}})],1):_vm._e(),(_vm.allowedBlocs.includes('section') == true)?_c('button',{staticClass:"btn btn-grey btn-icon",on:{"click":function($event){$event.preventDefault();_vm.$parent.addNewBlock(_vm.block.id, 'section', _vm.position)
          _vm.visible = false}}},[_c('font-awesome-icon',{attrs:{"icon":"grip-lines"}})],1):_vm._e(),(_vm.allowedBlocs.includes('code') == true)?_c('button',{staticClass:"btn btn-grey btn-icon",on:{"click":function($event){$event.preventDefault();_vm.$parent.addNewBlock(_vm.block.id, 'code', _vm.position)
          _vm.visible = false}}},[_c('font-awesome-icon',{attrs:{"icon":"code"}})],1):_vm._e()])]),_c('button',{staticClass:"btn btn-secondary btn-sm btn-icon btn-round",attrs:{"id":("fluxeditor-block-menu-" + _vm.uuid)}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }