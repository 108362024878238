<template>
  <div v-if="debug === true">
    DEBUG
    <pre><slot></slot></pre>
  </div>
</template>
<script>
export default {
  name: 'Debug',
  data() {
    return {
      debug: process.env.NODE_ENV === 'development' || false,
    }
  },
}
</script>
