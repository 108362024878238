<template>
  <div class="flux-editor-audio">
    <div v-if="block.attributes && !form.src">
      <h2 class="h4">Copier/coller le code html source</h2>
      <div>
        <el-input
          v-model="form.source"
          type="textarea"
          :rows="6"
          @input="updateAttributes()"
        />
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  name: 'FluxEditorCode',
  props: {
    block: {
      type: Object,
      default() {
        return {}
      },
    },
    attachments: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      displayTools: false,
      displayProperties: false,
      aligns: ['text-left', 'text-center', 'text-right'],
      form: {
        src: null,
        title: null,
        credentials: null,
        credentials_align: null,
      },
    }
  },
  computed: {
    audioFiles() {
      const results = []
      for (const key in this.attachments) {
        if (
          this.attachments[key].meta &&
          this.attachments[key].meta.type &&
          this.attachments[key].meta.type === 'audio'
        ) {
          results.push(this.attachments[key])
        }
      }
      return results
    },
  },
  created() {
    this.form = JSON.parse(JSON.stringify(this.block.attributes))
  },
  methods: {
    setFile(file) {
      this.$parent.updateBlockAttributes(this.block.id, {
        src: file.static_url,
      })
      this.form.src = file.static_url
      this.$forceUpdate()
    },
    coverHover() {
      this.displayTools = true
    },
    toolsHover(event) {
      event.stopPropagation()
      this.displayTools = true
    },
    updateAttributes() {
      this.$parent.updateBlockAttributes(this.block.id, this.form)
    },
  },
}
</script>
<style scoped>
.flux-editor-audio-container {
  position: relative;
}

.flux-editor-audio-container:hover .flux-editor-audio-cover {
  background-color: black;
  opacity: 0.2;
}
.flux-editor-audio-cover {
  z-index: 1;
  position: absolute;
  /*background-color: red;*/
  opacity: 0;
  width: 100%;
  height: 100%;
}
.flux-editor-audio-tools {
  z-index: 2;
  position: absolute;
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 25px;
  background-color: white;
  padding: 0 10px;
  border: 1px solid black;
  border-radius: 5px;
}
.flux-editor-audio-player {
  width: 100%;
}
</style>
