<template>
  <div class="flux-editor-section">
    <div v-if="block.attributes && block.attributes.type" class="row">
      <div class="col"></div>
      <div class="col text-center text-uppercase">
        <div class="p-5">
          <fa :icon="['fas', 'grip-lines']" />
          <select v-model="form.type" @change="updateAttributes()">
            <option v-for="scheme in schemes" :key="scheme" :value="scheme">
              {{ $t(`fluxeditor.${scheme}`) }}
            </option>
          </select>
          <fa :icon="['fas', 'grip-lines']" />
        </div>
      </div>
      <div class="col text-right">
        <div class="p-5">
          <a
            href
            class="d-inline-block text-white"
            @click.prevent="$parent.deleteBlock(block.id)"
          >
            <fa :icon="['fas', 'trash']" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  name: 'FluxEditorVideo',
  props: {
    block: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      schemes: ['off', 'boxed', 'story'],
      form: {
        type: 'boxed',
      },
    }
  },
  created() {
    this.form = JSON.parse(JSON.stringify(this.block.attributes))
  },
  methods: {
    updateAttributes() {
      this.$parent.updateBlockAttributes(this.block.id, this.form)
    },
  },
}
</script>
<style scoped>
.flux-editor-section {
  background-color: #66615b;
  min-height: 5px;
  color: white;
}
</style>
