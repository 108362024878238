<template>
  <div class="flux-editor-image">
    <figure v-if="block.attributes && form.slides && form.slides.length > 0">
      <div class="flux-editor-image-container">
        <div
          class="flux-editor-image-cover"
          @mouseover.stop="coverHover"
          @mouseleave="displayTools = false"
        ></div>
        <div
          v-if="displayTools === true"
          class="flux-editor-image-tools text-center"
          @mouseover.stop="toolsHover"
        >
          <button
            :class="{ active: displayProperties }"
            class="btn btn-simple btn-icon"
            @click.prevent="displayProperties = !displayProperties"
          >
            <fa :icon="['fas', 'cogs']" />
          </button>
          <button
            :class="{ active: displayImages }"
            class="btn btn-simple btn-icon"
            @click.prevent="displayImages = !displayImages"
          >
            <fa :icon="['fas', 'images']" />
          </button>
          <button
            class="btn btn-simple btn-icon"
            @click.prevent="$parent.deleteBlock(block.id)"
          >
            <fa :icon="['fas', 'trash']" />
          </button>
        </div>
        <div
          :class="{
            'embed-responsive embed-responsive-4by3':
              block.attributes.aspect === '4/3',
            'embed-responsive embed-responsive-16by9':
              block.attributes.aspect === '16/9',
            'embed-responsive embed-responsive-1by1':
              block.attributes.aspect === '1/1',
            'embed-responsive embed-responsive-21by9':
              block.attributes.aspect === '21/9',
            'img-fluid': block.attributes.aspect === 'fluid',
          }"
        >
          <b-carousel
            v-model="currentSlide"
            :interval="interval"
            controls
            indicators
            background="#ababab"
            img-width="100%"
            img-height="100%"
            style="text-shadow: 1px 1px 2px #333"
            class="embed-responsive-item"
          >
            <b-carousel-slide
              v-for="(slide, key) in form.slides"
              :key="key"
              :caption="slide.title"
              :text="slide.description"
              :img-src="slide.src"
            />
          </b-carousel>
        </div>
      </div>
      <figcaption
        v-if="block.attributes.title || block.attributes.credentials"
        :class="block.attributes.credentials_align"
      >
        <span v-if="block.attributes.title">
          {{ block.attributes.title }}
        </span>
        <span v-if="block.attributes.credentials">
          <br />
          {{ block.attributes.credentials }}
        </span>
      </figcaption>
    </figure>
    <el-form
      v-if="displayProperties === true"
      ref="form"
      size="small"
      :model="form"
      label-width="120px"
    >
      <el-form-item label="Aspect">
        <el-radio-group v-model="form.aspect" @change="updateAttributes()">
          <el-radio-button v-for="item in aspects" :key="item" :label="item">
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Durée de la transition (ms)" prop="interval">
        <el-input
          v-model="form.interval"
          type="number"
          @input="updateAttributes()"
        />
      </el-form-item>
      <el-form-item label="Légende" prop="title">
        <el-input v-model="form.title" @input="updateAttributes()" />
      </el-form-item>
      <el-form-item label="Crédits" prop="credentials">
        <el-input
          v-model="form.credentials"
          type="textarea"
          @input="updateAttributes()"
        />
      </el-form-item>
      <el-form-item label="Alignement">
        <el-radio-group
          v-model="form.credentials_align"
          @change="updateAttributes()"
        >
          <el-radio-button v-for="item in aligns" :key="item" :label="item">
            {{ $t(item) }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div v-if="displayImages === true">
      <h2 class="h4">Choisissez les images</h2>
      <div class="row">
        <div
          v-for="image in images"
          :key="image.id"
          class="col-sm-3 text-center"
        >
          <a href @click.prevent="toggleImage(image)">
            <img :src="image.static_url" class="img-fluid" />
          </a>
          <fa
            v-if="imageSelected(image) === true"
            :icon="['fa', 'check-square']"
          />
          <fa v-else :icon="['fa', 'square']" class="text-muted" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  name: 'FluxEditorCarousel',
  props: {
    block: {
      type: Object,
      default() {
        return {}
      },
    },
    attachments: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      displayTools: false,
      displayProperties: false,
      displayImages: false,
      currentSlide: 0,
      aspects: ['1/1', '4/3', '16/9', '21/9', 'fluid'],
      fits: ['cover', 'contain'],
      aligns: ['text-left', 'text-center', 'text-right'],
      targets: ['_self', '_blank'],
      form: {
        src: null,
        slides: [],
        interval: 7000,
        aspect: null,
        fit: null,
        title: null,
        credentials: null,
        credentials_align: null,
      },
    }
  },
  computed: {
    images() {
      const results = []
      for (const key in this.attachments) {
        console.log(this.attachments[key])
        if (
          this.attachments[key].meta &&
          this.attachments[key].meta.type &&
          this.attachments[key].meta.type === 'image'
        ) {
          results.push(this.attachments[key])
        }
      }
      return results
    },
    interval() {
      let value = 4000
      if (this.form.interval) {
        value = parseInt(this.form.interval)
      }
      return value
    },
  },
  created() {
    this.form = JSON.parse(JSON.stringify(this.block.attributes))
    if (this.form && this.form.slides.length === 0) {
      this.displayImages = true
    }
  },
  methods: {
    toggleImage(image) {
      console.log(this.imageSelected(image))
      if (this.imageSelected(image) === false) {
        this.form.slides.push({
          title: '',
          description: '',
          src: image.static_url,
        })
      } else if (this.imageSelected(image) === true) {
        this.imageRemove(image)
      }
      this.$parent.updateBlockAttributes(this.block.id, {
        slides: JSON.parse(JSON.stringify(this.form.slides)),
      })
    },
    coverHover() {
      this.displayTools = true
    },
    toolsHover(event) {
      event.stopPropagation()
      this.displayTools = true
    },
    updateAttributes() {
      this.$parent.updateBlockAttributes(this.block.id, this.form)
    },
    imageSelected(image) {
      let selected = false
      this.form.slides.forEach((i) => {
        if (i.src === image.static_url) {
          selected = true
        }
      })
      return selected
    },
    imageRemove(image) {
      let key = null
      this.form.slides.forEach((i, k) => {
        if (i.src === image.static_url) {
          key = k
        }
      })
      this.form.slides.splice(key, 1)
    },
  },
}
</script>
<style scoped>
.flux-editor-image-container {
  position: relative;
}

.flux-editor-image-container:hover .flux-editor-image-cover {
  background-color: black;
  opacity: 0.2;
}
.flux-editor-image-cover {
  z-index: 1;
  position: absolute;
  /*background-color: red;*/
  opacity: 0;
  width: 100%;
  height: 100%;
}
.flux-editor-image-tools {
  z-index: 2000;
  position: absolute;
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 25px;
  background-color: white;
  padding: 0 10px;
  border: 1px solid black;
  border-radius: 5px;
}
</style>
