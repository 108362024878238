<template>
  <div class="flux-editor-video">
    <figure v-if="block.attributes && block.attributes.id">
      <div class="flux-editor-video-container">
        <div
          class="flux-editor-video-cover"
          @mouseenter.stop="coverHover"
          @mouseleave.stop="displayTools = false"
        ></div>
        <div
          v-if="displayTools === true"
          class="flux-editor-video-tools text-center"
          @mouseenter.stop="toolsHover"
        >
          <button
            :class="{ active: displayProperties }"
            class="btn btn-simple btn-icon"
            @click.prevent="displayProperties = !displayProperties"
          >
            <fa :icon="['fas', 'cogs']" />
          </button>
          <button
            class="btn btn-simple btn-icon"
            @click.prevent="$parent.deleteBlock(block.id)"
          >
            <fa :icon="['fas', 'trash']" />
          </button>
        </div>
        <div
          :class="{
            'embed-responsive embed-responsive-4by3':
              block.attributes.aspect === '4/3',
            'embed-responsive embed-responsive-16by9':
              block.attributes.aspect === '16/9',
            'embed-responsive embed-responsive-1by1':
              block.attributes.aspect === '1/1',
            'embed-responsive embed-responsive-21by9':
              block.attributes.aspect === '21/9',
          }"
        >
          <iframe
            v-if="block.attributes.source === 'YOUTUBE'"
            width="100%"
            height="100%"
            class="embed-responsive-item"
            :src="`https://www.youtube-nocookie.com/embed/${block.attributes.id}`"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <iframe
            v-if="block.attributes.source === 'PEERTUBE'"
            width="100%"
            height="100%"
            sandbox="allow-same-origin allow-scripts allow-popups"
            :src="`https://video.blast-info.fr/videos/embed/${block.attributes.id}`"
            frameborder="0"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <figcaption
        v-if="block.attributes.title || block.attributes.credentials"
        :class="block.attributes.credentials_align"
      >
        <span v-if="block.attributes.title">
          {{ block.attributes.title }}
        </span>
        <span v-if="block.attributes.credentials">
          <br />
          {{ block.attributes.credentials }}
        </span>
      </figcaption>
    </figure>
    <el-form
      v-if="displayProperties === true"
      ref="form"
      size="small"
      :model="form"
      label-width="120px"
    >
      <el-form-item v-if="block.attributes.id" label="Url">
        <a
          v-if="block.attributes.source === 'YOUTUBE'"
          :href="`https://youtu.be/${block.attributes.id}`"
          target="_blank"
        >
          {{ `https://youtu.be/${block.attributes.id}` }}
        </a>
        <a
          v-if="block.attributes.source === 'PEERTUBE'"
          :href="`https://video.blast-info.fr/w/${block.attributes.id}`"
          target="_blank"
        >
          {{ `https://video.blast-info.fr/w/${block.attributes.id}` }}
        </a>
      </el-form-item>
      <el-form-item label="Aspect">
        <el-radio-group v-model="form.aspect" @change="updateAttributes()">
          <el-radio-button v-for="item in aspects" :key="item" :label="item">
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Légende" prop="title">
        <el-input v-model="form.title" @input="updateAttributes()" />
      </el-form-item>
      <el-form-item label="Crédits" prop="credentials">
        <el-input
          v-model="form.credentials"
          type="textarea"
          @input="updateAttributes()"
        />
      </el-form-item>
      <el-form-item label="Alignement">
        <el-radio-group
          v-model="form.credentials_align"
          @change="updateAttributes()"
        >
          <el-radio-button v-for="item in aligns" :key="item" :label="item">
            {{ $t(item) }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <el-form
      v-if="block.attributes && !block.attributes.id"
      ref="formsource"
      size="small"
    >
      <el-form-item prop="source">
        <el-radio-group v-model="source" @change="updateAttributes()">
          <el-radio-button v-for="item in sources" :key="item" :label="item">
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item prop="url">
        <el-input
          v-model="url"
          placeholder="Copier/coller ici l’URL de partage"
        />
      </el-form-item>
      <el-form-item prop="url">
        <button
          class="btn btn-primary btn-block btn-sm mt-0 mb-0"
          @click.prevent="setVideo"
        >
          Valider
        </button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  name: 'FluxEditorVideo',
  props: {
    block: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      url: '',
      displayTools: false,
      displayProperties: false,
      sources: ['YOUTUBE'],
      source: 'YOUTUBE',
      aspects: ['1/1', '4/3', '16/9', '21/9'],
      aligns: ['text-left', 'text-center', 'text-right'],
      form: {
        id: null,
        source: null,
        aspect: null,
        title: null,
        credentials: null,
        credentials_align: null,
      },
    }
  },
  created() {
    this.form = JSON.parse(JSON.stringify(this.block.attributes))
  },
  methods: {
    setVideo() {
      let videoId
      if (this.url && this.source === 'YOUTUBE') {
        videoId = this.getIdFromUrl(this.url)
      }
      if (this.url && this.source === 'PEERTUBE') {
        const regex = /\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/
        let m
        if ((m = regex.exec(this.url)) !== null) {
          videoId = m[0]
        } else {
          videoId = this.url.split('/w/')[1]
        }
      }
      if (videoId) {
        this.$parent.updateBlockAttributes(this.block.id, {
          id: videoId,
          source: this.source,
        })
        this.form.id = videoId
        this.form.source = this.source
      }
    },
    coverHover() {
      this.displayTools = true
    },
    toolsHover(event) {
      event.stopPropagation()
      this.displayTools = true
    },
    updateAttributes() {
      this.$parent.updateBlockAttributes(this.block.id, this.form)
    },
    getIdFromUrl(url, opts) {
      if (opts === undefined) {
        opts = { fuzzy: true }
      }

      if (/youtu\.?be/.test(url)) {
        // Look first for known patterns
        let i
        const patterns = [
          /youtu\.be\/([^#&?]{11})/,
          /\?v=([^#&?]{11})/,
          /&v=([^#&?]{11})/,
          /embed\/([^#&?]{11})/,
          /\/v\/([^#&?]{11})/,
        ]

        // If any pattern matches, return the ID
        for (i = 0; i < patterns.length; ++i) {
          if (patterns[i].test(url)) {
            return patterns[i].exec(url)[1]
          }
        }

        if (opts.fuzzy) {
          const tokens = url.split(/[/&?=#.\s]/g)
          for (i = 0; i < tokens.length; ++i) {
            if (/^[^#&?]{11}$/.test(tokens[i])) {
              return tokens[i]
            }
          }
        }
      }

      return null
    },
  },
}
</script>
<style scoped>
.flux-editor-video-container {
  position: relative;
}
.flux-editor-video-container:hover .flux-editor-video-cover {
  background-color: black;
  opacity: 0.2;
}
.flux-editor-video-cover {
  z-index: 1;
  position: absolute;
  /*background-color: red;*/
  opacity: 0;
  width: 100%;
  height: 100%;
}
.flux-editor-video-tools {
  z-index: 2;
  position: absolute;
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 25px;
  background-color: white;
  padding: 0 10px;
  border: 1px solid black;
  border-radius: 5px;
}
</style>
