<template>
  <div>
    <div
      v-for="(block, index) in content.blocks"
      :key="block.id"
      class="fluxeditor"
    >
      <div v-if="index === 0" class="fluxeditor-wrapper fluxeditor-plus">
        <div></div>
        <flux-editor-plus
          :block="block"
          :allowed-blocs="allowedBlocs"
          :uuid="uuidv4()"
          position="before"
        />
        <div></div>
      </div>
      <div class="fluxeditor-wrapper">
        <div class="fluxeditor-guide"></div>
        <div
          v-if="block.scheme === 'text'"
          :id="block.id"
          :ref="block.id"
          class="fluxeditor-block"
          @keydown="doEdit"
          @click="enableBlock(block.id, true, $event)"
          v-html="block.attributes.source"
        />
        <flux-editor-image
          v-if="block.scheme === 'image'"
          :id="block.id"
          :ref="block.id"
          :block="block"
          :attachments="attachments"
          class="fluxeditor-block"
        />
        <flux-editor-video
          v-if="block.scheme === 'video'"
          :id="block.id"
          :ref="block.id"
          :block="block"
          class="fluxeditor-block"
        />
        <flux-editor-carousel
          v-if="block.scheme === 'carousel'"
          :id="block.id"
          :ref="block.id"
          :block="block"
          :attachments="attachments"
          class="fluxeditor-block"
        />
        <flux-editor-audio
          v-if="block.scheme === 'audio'"
          :id="block.id"
          :ref="block.id"
          :block="block"
          :attachments="attachments"
          class="fluxeditor-block"
        />
        <flux-editor-read-more
          v-if="block.scheme === 'readmore'"
          :id="block.id"
          :ref="block.id"
          :block="block"
          class="fluxeditor-block"
        />
        <flux-editor-section
          v-if="block.scheme === 'section'"
          :id="block.id"
          :ref="block.id"
          :block="block"
          class="fluxeditor-block"
        />
        <flux-editor-code
          v-if="block.scheme === 'code'"
          :id="block.id"
          :ref="block.id"
          :block="block"
          class="fluxeditor-block"
        />
        <div class="fluxeditor-guide"></div>
      </div>
      <div class="fluxeditor-wrapper fluxeditor-plus">
        <div></div>
        <flux-editor-plus
          :block="block"
          :allowed-blocs="allowedBlocs"
          :uuid="uuidv4()"
        />
        <div></div>
      </div>
    </div>
    <!-- <pre>{{ content.blocks }}</pre> -->
  </div>
</template>
<script>
/* eslint-disable */
import MediumEditor from 'medium-editor'
import FluxEditorPlus from './FluxEditorPlus.vue'
import FluxEditorImage from './FluxEditorImage.vue'
import FluxEditorCarousel from './FluxEditorCarousel.vue'
import FluxEditorVideo from './FluxEditorVideo.vue'
import FluxEditorAudio from './FluxEditorAudio.vue'
import FluxEditorReadMore from './FluxEditorReadMore.vue'
import FluxEditorCode from './FluxEditorCode.vue'
import FluxEditorSection from './FluxEditorSection.vue'

const blocksReferences = {
  text: {
    scheme: 'text',
    attributes: {
      tag: 'p',
      source: '',
    },
    id: null,
  },
  image: {
    scheme: 'image',
    attributes: {
      src: null,
      href: null,
      href_target: '_self',
      title: null,
      credentials: null,
      credentials_align: 'text-center',
      aspect: '4/3',
      fit: 'contain',
    },
    id: null,
  },
  carousel: {
    scheme: 'carousel',
    attributes: {
      slides: [],
      interval: 7000,
      title: null,
      credentials: null,
      credentials_align: 'text-center',
      aspect: 'fluid',
      fit: 'contain',
    },
    id: null,
  },
  video: {
    scheme: 'video',
    attributes: {
      id: null,
      source: 'PEERTUBE',
      aspect: '16/9',
      title: null,
      credentials: null,
      credentials_align: 'text-center',
    },
    id: null,
  },
  audio: {
    scheme: 'audio',
    attributes: {
      title: null,
      credentials: null,
      credentials_align: 'text-center',
    },
    id: null,
  },
  readmore: {
    scheme: 'readmore',
    attributes: {
      label: 'Lire aussi',
      stories: [],
    },
    id: null,
  },
  section: {
    scheme: 'section',
    attributes: {
      type: 'boxed',
    },
    id: null,
  },
  code: {
    scheme: 'code',
    attributes: {
      source: '',
    },
    id: null,
  },
}

export default {
  name: 'FluxEditorCore',
  components: {
    FluxEditorPlus,
    FluxEditorImage,
    FluxEditorCarousel,
    FluxEditorVideo,
    FluxEditorAudio,
    FluxEditorSection,
    FluxEditorReadMore,
    FluxEditorCode,
  },
  props: {
    contents: {
      type: Object,
      default() {
        return {}
      },
    },
    allowedBlocs: {
      type: Array,
      default() {
        return [
          'text',
          'image',
          'video',
          // 'carousel',
          // 'audio',
          // 'readmore',
          // 'section',
          // 'code',
        ]
      },
    },
    attachments: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      references: blocksReferences,
      content: {
        blocks: [],
      },
      editor: null,
      editorBlockId: null,
    }
  },
  computed: {
    blocks() {
      return this.content.blocks
    },
  },
  created() {
    console.log(JSON.parse(JSON.stringify(this.contents)))
    this.initEditor(this.contents)
  },
  methods: {
    getContent() {
      this.disableEditor()
      return Object.assign(this.content, {})
    },
    async initEditor(content) {
      if (content && content.blocks && content.blocks.length > 0) {
        this.content = content
      } else {
        if (!this.content.blocks) {
          this.content.blocks = []
        }
        if (this.content.blocks.length === 0) {
          this.content.blocks.push(this.getBlockFromReference('text'))
        }
        const block = this.getFirstTextBlock()
        await this.timeout(100)
        this.enableBlock(block.id)
      }
    },
    async doEdit(ev) {
      // console.log(ev.keyCode)
      if (ev.key === 'Enter' && ev.shiftKey === false) {
        ev.preventDefault()
        const block = this.addBlockAtCaret(ev.target.id, 'text') // eslint-disable-line
        if (block) {
          this.disableEditor()
          await this.timeout(100)
          this.enableBlock(block.id)
          this.focusBlockById(block.id)
        }
      } else if (ev.key === 'Backspace') {
        const position = this.getCaretPosition(ev.target)
        const block = this.getPreviousSinblingBlock(ev.target.id)
        if (position === 'start' && this.isBlockEmpty(ev.target.id) === true) {
          this.disableEditor()
          this.deleteBlock(ev.target.id)
          if (block) {
            this.enableBlock(block.id)
            await this.timeout(100)
            this.focusBlockById(block.id)
            this.setCaretToBlockEnd(block.id)
          }
        } else if (
          position === 'start' &&
          this.isBlockEmpty(ev.target.id) === false
        ) {
          let content = this.getBlockHtmlContent(ev.target.id)
          if (block) {
            content = block.attributes.source + content
            this.disableEditor()
            this.deleteBlock(ev.target.id)
            this.updateBlockAttributes(block.id, { source: content })
            this.enableBlock(block.id)
            await this.timeout(100)
            this.focusBlockById(block.id)
            this.setCaretToBlockEnd(block.id)
          }
        }
      } else if (ev.key === 'ArrowUp') {
        const block = this.getPreviousSinblingBlock(ev.target.id) // eslint-disable-line
        if (block && block.scheme === 'text') {
          const position = this.getCaretPosition(ev.target) // eslint-disable-line
          if (position === 'start') {
            this.enableBlock(block.id)
            this.focusBlockById(block.id)
            await this.timeout(50)
            this.setCaretToBlockEnd(block.id)
          }
        }
      } else if (ev.key === 'ArrowDown') {
        const block = this.getNextSinblingBlock(ev.target.id) // eslint-disable-line
        if (block && block.scheme === 'text') {
          const position = this.getCaretPosition(ev.target) // eslint-disable-line
          if (position === 'end' || this.isBlockEmpty(ev.target.id) === true) {
            this.enableBlock(block.id)
            this.focusBlockById(block.id)
          }
        }
      }
    },
    enableBlock(id, focus) {
      const block = this.getBlockById(id)
      if (block && block.scheme === 'text') {
        if (this.editorBlockId !== id) {
          this.disableEditor()
          this.editorBlockId = id
          if (this.$refs[id]) {
            this.editor = new MediumEditor(this.$refs[id][0], {
              imageDragging: false,
              anchorPreview: false,
              targetBlank: true,
              placeholder: {
                text: 'Paragraphe vide',
                hideOnClick: true,
              },
              toolbar: {
                buttons: [
                  'h2',
                  'h3',
                  'h4',
                  'quote',
                  'bold',
                  'italic',
                  'superscript',
                  'anchor',
                  'removeFormat',
                ],
              },
              anchor: {
                placeholderText: 'Saississez votre lien',
              },
              commands: [
                {
                  command: 'bold',
                  key: 'B',
                  meta: true,
                  shift: false,
                  alt: false,
                },
                {
                  command: 'italic',
                  key: 'I',
                  meta: true,
                  shift: false,
                  alt: false,
                },
              ],
            })
            if (focus === true) {
              this.$refs[id][0].focus()
            }
          }
        }
      }
    },
    disableEditor() {
      if (this.editor) {
        const source = this.editor.getContent()
        this.editor.destroy()
        if (this.editorBlockId) {
          const block = this.getBlockById(this.editorBlockId)
          if (block) {
            this.content.blocks[block.key].attributes.source = source
          }
        }
        this.editorBlockId = null
      }
    },
    // appendContentToBlock(id, htmlString) {
    appendContentToBlock(id) {
      const block = this.getBlockById(id)
      const div = document.createElement('div')
      div.innerHTML = block.attributes.source.trim()
      console.log(div.innerHTML)
      // const html = this.getContentFromHTML(div.innerHTML)
      // const content = `<${block.attributes.tag}>${html} ${htmlString}</${block.attributes.tag}>`
      // this.content.blocks[block.key].attributes.source = content
    },
    getBlockFromReference(scheme) {
      const block = JSON.parse(JSON.stringify(blocksReferences[scheme]))
      block.id = this.uuidv4()
      return block
    },
    async addNewBlock(id, scheme, position) {
      this.disableEditor()
      const block = this.getBlockById(id)
      const newBlock = this.getBlockFromReference(scheme)
      if (block && newBlock) {
        if (position === 'before') {
          this.content.blocks.unshift(newBlock)
        } else {
          this.content.blocks.splice(block.key + 1, 0, newBlock)
        }
        if (scheme === 'text') {
          await this.timeout(100)
          this.enableBlock(newBlock.id, true)
        }
        return newBlock
      }
      return null
    },
    updateBlockAttributes(id, attributes) {
      const block = this.getBlockById(id)
      if (block) {
        this.content.blocks[block.key].attributes = Object.assign(
          block.attributes,
          attributes
        )
      }
    },
    addBlockAtCaret(id, scheme) {
      const uuid = this.uuidv4()
      const block = this.getBlockById(id)
      const el = document.getElementById(block.id)
      if (el && block) {
        const htmlBefore = this.getHtmlAtCaret(el)
        const htmlAfter = this.getHtmlAtCaret(el, true)
        this.disableEditor()
        this.content.blocks[block.key].attributes.source = htmlBefore
        this.content.blocks.splice(block.key + 1, 0, {
          scheme,
          attributes: {
            tag: 'p',
            source: htmlAfter,
          },
          id: uuid,
        })
        return this.getBlockById(uuid)
      }
      return null
    },
    getBlockById(id) {
      for (const key in this.content.blocks) {
        if (id && this.content.blocks[key].id === id) {
          return Object.assign(this.content.blocks[key], { key: parseInt(key) })
        }
      }
      return null
    },
    getFirstTextBlock() {
      for (const key in this.content.blocks) {
        if (this.content.blocks[key].scheme === 'text') {
          return Object.assign(this.content.blocks[key], {})
        }
      }
    },
    focusBlockById(id, ms) {
      try {
        const el = document.getElementById(id)
        if (el) {
          el.focus()
        }
      } catch (e) {
        console.log(e)
      }
    },
    getPreviousSinblingBlock(id) {
      const current = this.getBlockById(id)
      const previousKey = current.key
      const blocks = this.content.blocks.slice(0, current.key).reverse()
      if (previousKey >= 0) {
        for (const key in blocks) {
          if (blocks[key].scheme === current.scheme) {
            return Object.assign(blocks[key], { key: parseInt(key) })
          }
        }
      }
    },
    getNextSinblingBlock(id) {
      const current = this.getBlockById(id)
      const nextKey = current.key + 1
      for (const key in this.content.blocks.slice(nextKey)) {
        const k = parseInt(key) + nextKey
        if (this.content.blocks[k].scheme === current.scheme) {
          return Object.assign(this.content.blocks[k], { key: k })
        }
      }
    },
    deleteBlock(id) {
      const block = this.getBlockById(id)
      this.content.blocks.splice(block.key, 1)
    },
    isBlockEmpty(id) {
      const el = document.getElementById(id)
      if (el && el.textContent.trim().length === 0) {
        return true
      }
      return false
    },
    setCaretToBlockEnd(id) {
      const el = document.getElementById(id)
      if (el) {
        this.setCaretToEnd(el)
      }
    },
    setCaretToEnd(el) {
      const range = document.createRange()
      range.selectNodeContents(el)
      range.collapse(false)
      const selection = window.getSelection()
      selection.removeAllRanges()
      selection.addRange(range)

      // const node = el.childNodes[0]
      // console.log(node)
      // console.log(node.nodeType)

      // const range = document.createRange()
      // range.selectNodeContents(el)
      // range.collapse(true)
      // const sel = window.getSelection()
      // sel.removeAllRanges()
      // sel.addRange(range)
    },
    getCaretPosition(el) {
      const sel = window.getSelection()
      let atStart
      let atEnd
      if (sel.rangeCount) {
        const selRange = sel.getRangeAt(0)
        const testRange = selRange.cloneRange()

        testRange.selectNodeContents(el)
        testRange.setEnd(selRange.startContainer, selRange.startOffset)
        atStart = testRange.toString().trim() === ''

        testRange.selectNodeContents(el)
        testRange.setStart(selRange.endContainer, selRange.endOffset)
        atEnd = testRange.toString().trim() === ''
      }

      if (atStart) {
        return 'start'
      } else if (atEnd) {
        return 'end'
      } else {
        return null
      }
    },
    getHtmlAtCaret(el, after) {
      let html = ''
      const sel = window.getSelection()
      if (sel.rangeCount > 0) {
        const selRange = sel.getRangeAt(0)
        const range = document.createRange()
        range.selectNodeContents(el)
        if (!after) {
          range.setEnd(selRange.startContainer, selRange.startOffset)
        } else {
          range.setStart(selRange.startContainer, selRange.startOffset)
        }
        const frag = range.cloneContents()
        el = document.createElement('body')
        el.appendChild(frag)
        if (el.textContent.trim().length === 0) {
          html = ''
        } else {
          html = el.innerHTML.replace('<p><br></p>', '')
        }
      }
      return html
    },
    getBlockHtmlContent(id) {
      const el = document.getElementById(id)
      return el.innerHTML
    },
    getBlockTextContent(id) {
      const el = document.getElementById(id)
      return el.textContent
    },
    getContentFromHTML(htmlString) {
      const div = document.createElement('div')
      div.innerHTML = htmlString.trim()
      if (div.firstChild && div.firstChild.innerHTML) {
        return div.firstChild.innerHTML.trim()
      } else {
        return div.innerHTML
      }
    },
    getTextFromHTML(htmlString) {
      const div = document.createElement('div')
      div.innerHTML = htmlString.trim()
      if (div.firstChild && div.firstChild.textContent) {
        return div.firstChild.textContent.trim()
      } else {
        return div.textContent
      }
    },
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      )
    },
    timeout(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },
    MediumEditor,
  },
}
</script>
<style>
.fluxeditor-plus {
  padding-top: 20px;
  padding-bottom: 20px;
}

.fluxeditor-wrapper {
  display: grid;
  grid-template-columns: 22px auto 22px;
}

.fluxeditor-guide {
  margin: 2px 10px 2px 10px;
  background-color: #ccc;
}

.fluxeditor-block {
  min-height: 25px;
}

.fluxeditor-block p {
  color: #282828;
  font-size: 1.1rem;
  line-height: 1.6rem;
}

.fluxeditor-block h2 {
  margin: 0 0 5px 0;
}

.fluxeditor-block h3 {
  margin: 0 0 5px 0;
}
.fluxeditor-block p {
  margin: 0 0 5px 0;
}
</style>
