<template>
  <div>
    <pagination
      v-model="proxyPage"
      :records="proxyTotal"
      :per-page="proxyPerPage"
      :options="options"
      @paginate="paginate"
    />
    <!-- <pre>{{ page }} -> {{ proxyPage }}</pre> -->
    <!-- <pre>{{ total }} -> {{ proxyTotal }}</pre> -->
    <!-- <pre>{{ perPage }} -> {{ proxyPerPage }}</pre> -->
  </div>
</template>

<script>
export default {
  name: 'PaginationCustom',
  components: {},
  inheritAttrs: false,
  props: {
    page: {
      default: 1,
      type: [String, Number],
    },
    total: {
      default: 0,
      type: [String, Number],
    },
    perPage: {
      default: 20,
      type: [String, Number],
    },
  },
  data() {
    return {
      proxyPage: 1,
      proxyTotal: 0,
      proxyPerPage: 20,
      options: {
        theme: 'bootstrap4',
        chunk: 5,
        texts: { count: '' },
      },
    }
  },
  watch: {
    page() {
      this.init()
    },
    total() {
      this.init()
    },
    perPage() {
      this.init()
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.proxyTotal = Number(this.total)
      this.proxyPage = Number(this.page)
      this.proxyPerPage = Number(this.perPage)
    },
    paginate(page) {
      this.$emit('change', page)
    },
  },
}
</script>

<style>
.VuePagination {
  display: inline-block;
}
</style>
