<template>
  <div class="text-center">
    <b-popover
      :target="`fluxeditor-block-menu-${uuid}`"
      placement="top"
      triggers="focus"
    >
      <div class="text-center">
        <button
          v-if="allowedBlocs.includes('text') == true"
          class="btn btn-grey btn-icon"
          @click.prevent="
            $parent.addNewBlock(block.id, 'text', position)
            visible = false
          "
        >
          <font-awesome-icon icon="paragraph" />
        </button>
        <button
          v-if="allowedBlocs.includes('image') == true"
          class="btn btn-grey btn-icon"
          @click.prevent="
            $parent.addNewBlock(block.id, 'image', position)
            visible = false
          "
        >
          <font-awesome-icon icon="image" />
        </button>
        <button
          v-if="allowedBlocs.includes('carousel') == true"
          class="btn btn-grey btn-icon"
          @click.prevent="
            $parent.addNewBlock(block.id, 'carousel', position)
            visible = false
          "
        >
          <font-awesome-icon icon="images" />
        </button>
        <button
          v-if="allowedBlocs.includes('video') == true"
          class="btn btn-grey btn-icon"
          @click.prevent="
            $parent.addNewBlock(block.id, 'video', position)
            visible = false
          "
        >
          <font-awesome-icon icon="video" />
        </button>
        <button
          v-if="allowedBlocs.includes('audio') == true"
          class="btn btn-grey btn-icon"
          @click.prevent="
            $parent.addNewBlock(block.id, 'audio', position)
            visible = false
          "
        >
          <font-awesome-icon icon="volume-up" />
        </button>
        <button
          v-if="allowedBlocs.includes('readmore') == true"
          class="btn btn-grey btn-icon"
          @click.prevent="
            $parent.addNewBlock(block.id, 'readmore', position)
            visible = false
          "
        >
          <font-awesome-icon icon="newspaper" />
        </button>
        <button
          v-if="allowedBlocs.includes('section') == true"
          class="btn btn-grey btn-icon"
          @click.prevent="
            $parent.addNewBlock(block.id, 'section', position)
            visible = false
          "
        >
          <font-awesome-icon icon="grip-lines" />
        </button>
        <button
          v-if="allowedBlocs.includes('code') == true"
          class="btn btn-grey btn-icon"
          @click.prevent="
            $parent.addNewBlock(block.id, 'code', position)
            visible = false
          "
        >
          <font-awesome-icon icon="code" />
        </button>
      </div>
    </b-popover>
    <button
      :id="`fluxeditor-block-menu-${uuid}`"
      class="btn btn-secondary btn-sm btn-icon btn-round"
    >
      <font-awesome-icon icon="plus" />
    </button>
  </div>
</template>
<script>
import { BPopover } from 'bootstrap-vue'
/* eslint-disable */
export default {
  name: 'FluxEditorPlus',
  components: { BPopover },
  props: {
    block: {
      type: Object,
      default() {
        return {}
      },
    },
    allowedBlocs: {
      type: Array,
      default() {
        return [
          'text',
          'image',
          'video',
          'carousel',
          'audio',
          'readmore',
          'section',
          'code',
        ]
      },
    },
    position: {
      type: String,
      default: 'after',
    },
    uuid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      visible: false,
    }
  },
  created() {},
}
</script>
<style>
.popover {
  max-width: 350px !important;
}
</style>
