<template>
  <span :id="id" @click.prevent="copy()">
    {{ display || value }}
    <b-tooltip :target="id" triggers="hover">
      {{ tooltipTitle }}
    </b-tooltip>
  </span>
</template>

<script>
import { BTooltip } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'CopyValue',
  components: {
    BTooltip,
  },
  inheritAttrs: false,
  props: {
    id: {
      default: 'tooltip-target-1',
      type: String,
    },
    value: {
      default: null,
      type: String,
    },
    display: {
      default: null,
      type: String,
    },
    tooltipTitle: {
      default: 'Cliquez pour copier l’élément',
      type: String,
    },
  },
  data() {
    return {}
  },
  created() {},
  methods: {
    async copy() {
      try {
        await this.$copyText(this.value)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Élément copié`,
            icon: 'CopyIcon',
            variant: 'success',
          },
        })
      } catch (e) {
        console.error(e)
      }
    },
  },
}
</script>
