<template>
  <div class="flux-editor-readmore">
    <div class="flux-editor-readmore-container">
      <div
        v-if="displayProperties === false"
        class="flux-editor-readmore-cover"
        @mouseover.stop="coverHover"
        @mouseleave="displayTools = false"
      />
      <h2 v-if="form.label">{{ form.label }}</h2>
      <div
        v-if="displayTools === true && displayProperties === false"
        class="flux-editor-readmore-tools text-center"
        @mouseover.stop="toolsHover"
      >
        <button
          :class="{ active: displayProperties }"
          class="btn btn-simple btn-icon"
          @click.prevent="
            displayProperties = !displayProperties
            displayTools = false
          "
        >
          <fa :icon="['fas', 'cogs']" />
        </button>
        <button
          class="btn btn-simple btn-icon"
          @click.prevent="$parent.deleteBlock(block.id)"
        >
          <fa :icon="['fas', 'trash']" />
        </button>
      </div>
      <div v-if="displayProperties === true" class="mb-4 mt-4">
        <el-autocomplete
          ref="autocomplete"
          v-model="storyTitle"
          :trigger-on-focus="false"
          :highlight-first-item="highlightFirstItem"
          :fetch-suggestions="storiesFilter"
          prefix-icon="el-icon-search"
          placeholder="Rechercher un article et l’ajouter à la liste"
          class="flux-editor-autocomplete"
          @select="selectStory"
        />
      </div>
      <div>
        <table class="table">
          <tbody>
            <tr v-for="(story, key) in form.stories" :key="story.id">
              <td>
                <a :href="story.canonical_url" target="_blank">
                  {{ story.title }} |
                  {{ $dayjs(story.published_at).format('MM/YYYY') }}
                </a>
              </td>
              <td class="text-right">
                <button
                  v-if="displayProperties === true"
                  class="btn btn-secondary btn-sm"
                  @click.prevent="deleteStory(key)"
                >
                  <fa :icon="['fas', 'trash']" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="displayProperties === true" class="mb-4">
        <button
          class="btn btn-default btn-block btn-sm mt-0 mb-0"
          @click.prevent="displayProperties = false"
        >
          Fermer
        </button>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  name: 'FluxEditorReadMore',
  props: {
    block: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      displayTools: false,
      displayProperties: false,
      storyTitle: null,
      highlightFirstItem: true,
      form: {
        label: 'Lire aussi',
        stories: [],
      },
    }
  },
  created() {
    this.form = JSON.parse(JSON.stringify(this.block.attributes))
  },
  methods: {
    storiesFilter(queryString, cb) {
      if (queryString) {
        this.$axios
          .get('/stories/', {
            params: {
              term: queryString,
              scope: 'PUBLIC',
              status: 'PUBLISHED',
              per_page: 100,
            },
            headers: {
              'x-fields': 'results{id,scheme,title,published_at,canonical_url}',
            },
          })
          .then((response) => {
            const list = []
            response.data.results.map((value, key) => {
              list.push({
                value: `${this.$t(`story.${value.scheme}`)} - ${value.title}`,
                id: value.id,
                story: value,
              })
              return value
            })
            cb(list)
          })
      }
      return false
    },
    selectStory(value) {
      if (value) {
        this.storyTitle = null
        this.form.stories.push(value.story)
        this.updateAttributes()
      }
    },
    deleteStory(key) {
      this.form.stories.splice(key, 1)
      this.updateAttributes()
    },
    updateAttributes() {
      this.$parent.updateBlockAttributes(this.block.id, this.form)
    },
    coverHover() {
      this.displayTools = true
    },
    toolsHover(event) {
      event.stopPropagation()
      this.displayTools = true
    },
  },
}
</script>
<style scoped>
.flux-editor-readmore-container {
  position: relative;
}

.flux-editor-readmore-container:hover .flux-editor-readmore-cover {
  background-color: black;
  opacity: 0.2;
}
.flux-editor-readmore-cover {
  z-index: 1;
  position: absolute;
  /*background-color: red;*/
  opacity: 0;
  width: 100%;
  height: 100%;
}
.flux-editor-readmore-tools {
  z-index: 2;
  position: absolute;
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 25px;
  background-color: white;
  padding: 0 10px;
  border: 1px solid black;
  border-radius: 5px;
}
</style>
<style>
/*.flux-editor-autocomplete .el-input {
  width: 100%;
}
*/
.flux-editor-autocomplete {
  width: 100%;
}
</style>
