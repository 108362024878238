import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import CopyValue from '@core/components/CopyValue.vue'
import PaginationCustom from '@/components/PaginationCustom.vue'
import Debug from '@/components/Debug.vue'

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(CopyValue.name, CopyValue)
Vue.component(PaginationCustom.name, PaginationCustom)
Vue.component(Debug.name, Debug)
