import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/recipes',
      name: 'recipes',
      component: () => import('@/views/recipes/Recipes.vue'),
      meta: {
        pageTitle: 'Recettes',
        breadcrumb: [
          {
            text: 'Liste',
            active: true,
          },
        ],
      },
    },
    {
      path: '/productions',
      name: 'productions',
      component: () => import('@/views/productions/Productions.vue'),
      meta: {
        pageTitle: 'Fabrication',
        breadcrumb: [
          {
            text: 'Liste',
            active: true,
          },
        ],
      },
    },
    {
      path: '/productions/forecast',
      name: 'productionForecast',
      component: () => import('@/views/productions/ProductionForecast.vue'),
      meta: {
        pageTitle: 'Prévisionnel',
        breadcrumb: [
          {
            text: 'Détail',
            active: true,
          },
        ],
      },
    },
    {
      path: '/productions/tasks/:id',
      name: 'production',
      component: () => import('@/views/productions/ProductionTask.vue'),
      meta: {
        pageTitle: 'Fabrication',
        breadcrumb: [
          {
            text: 'Détail',
            active: true,
          },
        ],
      },
    },
    {
      path: '/productions/batches/:id',
      name: 'productionBatch',
      component: () => import('@/views/productions/ProductionBatch.vue'),
      meta: {
        pageTitle: 'Tâches planifiées',
        breadcrumb: [
          {
            text: 'Détail',
            active: true,
          },
        ],
      },
    },
    {
      path: '/recipes/:id',
      name: 'recipe',
      component: () => import('@/views/recipes/Recipe.vue'),
      meta: {
        pageTitle: 'Recette',
        breadcrumb: [
          {
            text: 'Fiche',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ingredients',
      name: 'ingredients',
      component: () => import('@/views/ingredients/Ingredients.vue'),
      meta: {
        pageTitle: 'Ingrédients',
        breadcrumb: [
          {
            text: 'Liste',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ingredients/:id',
      name: 'ingredient',
      component: () => import('@/views/ingredients/Ingredient.vue'),
      meta: {
        pageTitle: 'Ingrédient',
        breadcrumb: [
          {
            text: 'Liste',
            active: false,
          },
          {
            text: 'Fiche',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users',
      name: 'clients',
      component: () => import('@/views/users/Users.vue'),
      meta: {
        pageTitle: 'Clients',
        breadcrumbs: [
          {
            text: 'Liste',
            active: false,
          },
        ],
      },
    },
    {
      path: '/users/:id',
      name: 'client',
      component: () => import('@/views/users/User.vue'),
      meta: {
        pageTitle: 'Client',
        breadcrumb: [
          {
            text: 'Détails',
            active: false,
          },
        ],
      },
    },
    {
      path: '/selections/',
      name: 'selections',
      component: () => import('@/views/selections/Selections.vue'),
      meta: {
        pageTitle: 'Sélections des recettes hebdomadaires',
        breadcrumb: [
          {
            text: 'Liste',
            active: false,
          },
        ],
      },
    },
    {
      path: '/selections/:id',
      name: 'selection',
      component: () => import('@/views/selections/Selection.vue'),
      meta: {
        pageTitle: 'Sélection',
        breadcrumb: [
          {
            text: 'Propriétés',
            active: false,
          },
        ],
      },
    },
    {
      path: '/orders/',
      name: 'orders',
      component: () => import('@/views/orders/Orders.vue'),
      meta: {
        pageTitle: 'Commandes',
        breadcrumb: [
          {
            text: 'Liste',
            active: false,
          },
        ],
      },
    },
    {
      path: '/orders/:id',
      name: 'order',
      component: () => import('@/views/orders/Order.vue'),
      meta: {
        pageTitle: 'Commande',
        breadcrumb: [
          {
            text: 'Détails',
            active: false,
          },
        ],
      },
    },
    {
      path: '/orders-coupons/',
      name: 'orders_coupons',
      component: () => import('@/views/coupons/Coupons.vue'),
      meta: {
        pageTitle: 'Coupons de réductions',
        breadcrumb: [
          {
            text: 'Liste',
            active: false,
          },
        ],
      },
    },
    {
      path: '/orders-coupons/:id',
      name: 'order_coupon',
      component: () => import('@/views/coupons/Coupon.vue'),
      meta: {
        pageTitle: 'Coupon de réduction',
        breadcrumb: [
          {
            text: 'Détails',
            active: false,
          },
        ],
      },
    },
    {
      path: '/coupons/',
      name: 'coupons',
      component: () => import('@/views/coupons/Coupons.vue'),
      meta: {
        pageTitle: 'Coupons de réductions',
        breadcrumb: [
          {
            text: 'Liste',
            active: false,
          },
        ],
      },
    },
    {
      path: '/coupons/:id',
      name: 'coupon',
      component: () => import('@/views/coupons/Coupon.vue'),
      meta: {
        pageTitle: 'Coupon de réduction',
        breadcrumb: [
          {
            text: 'Détails',
            active: false,
          },
        ],
      },
    },
    {
      path: '/payments',
      name: 'payments',
      component: () => import('@/views/payments/Payments.vue'),
      meta: {
        pageTitle: 'Transactions',
        breadcrumb: [
          {
            text: 'Liste',
            active: false,
          },
        ],
      },
    },
    {
      path: '/reviews',
      name: 'reviews',
      component: () => import('@/views/reviews/Reviews.vue'),
      meta: {
        pageTitle: 'Avis clients',
        breadcrumb: [
          {
            text: 'Liste',
            active: false,
          },
        ],
      },
    },
    {
      path: '/evaluations',
      name: 'evaluations',
      component: () => import('@/views/evaluations/Evaluations.vue'),
      meta: {
        pageTitle: 'Évaluation des recettes',
        breadcrumb: [
          {
            text: 'Liste',
            active: false,
          },
        ],
      },
    },
    {
      path: '/subscriptions',
      name: 'subscriptions',
      component: () => import('@/views/subscriptions/Subscriptions.vue'),
      meta: {
        pageTitle: 'Abonnements',
        breadcrumb: [
          {
            text: 'Liste',
            active: false,
          },
        ],
      },
    },
    {
      path: '/subscriptions/:id',
      name: 'subscription',
      component: () => import('@/views/subscriptions/Subscription.vue'),
      meta: {
        pageTitle: 'Abonnement',
        breadcrumb: [
          {
            text: 'Détails',
            active: false,
          },
        ],
      },
    },
    {
      path: '/subscriptions/selections/:id',
      name: 'subscription_selection',
      component: () =>
        import('@/views/subscriptions/SubscriptionSelection.vue'),
      meta: {
        pageTitle: 'Abonnement / Sélection',
        breadcrumb: [
          {
            text: 'Sélection des plats',
            active: false,
          },
        ],
      },
    },
    {
      path: '/plans',
      name: 'subscriptions_plans',
      component: () => import('@/views/plans/Plans.vue'),
      meta: {
        pageTitle: 'Formules',
        breadcrumb: [
          {
            text: 'Liste',
            active: false,
          },
        ],
      },
    },
    {
      path: '/plans/:id',
      name: 'subscriptions_plan',
      component: () => import('@/views/plans/Plan.vue'),
      meta: {
        pageTitle: 'Formule',
        breadcrumb: [
          {
            text: 'Détails',
            active: false,
          },
        ],
      },
    },
    {
      path: '/exports',
      name: 'exports',
      component: () => import('@/views/exports/Exports.vue'),
      meta: {
        pageTitle: 'Exports',
        breadcrumb: [
          {
            text: 'Liste',
            active: false,
          },
        ],
      },
    },
    {
      path: '/stories',
      name: 'stories',
      component: () => import('@/views/stories/Stories.vue'),
      meta: {
        pageTitle: 'Blogs',
        breadcrumb: [
          {
            text: 'Liste',
            active: false,
          },
        ],
      },
    },
    {
      path: '/stories/:id',
      name: 'story',
      component: () => import('@/views/stories/Story.vue'),
      meta: {
        pageTitle: 'Blog',
        breadcrumb: [
          {
            text: 'Détails',
            active: false,
          },
        ],
      },
    },
    {
      path: '/stories/:id/edit',
      name: 'story_editor',
      component: () => import('@/views/stories/StoryEdit.vue'),
      meta: {
        pageTitle: 'Blog - rédaction',
        breadcrumb: [
          {
            text: 'Editeur',
            active: false,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
