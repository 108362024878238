export default {
  namespaced: true,
  state: {
    profile: {},
  },
  getters: {
    profile: (state) => {
      return state.profile
    },
  },
  mutations: {
    INITIALISE(state) {
      Object.assign(
        state.profile,
        JSON.parse(localStorage.getItem('lm_user')) || {}
      )
    },
    UPDATE(state, obj) {
      localStorage.setItem('lm_user', JSON.stringify(obj))
      Object.assign(state.profile, obj)
    },
  },
  actions: {},
}
