<template>
  <div class="flux-editor-audio">
    <figure v-if="block.attributes && form.src">
      <div class="flux-editor-audio-container">
        <div
          class="flux-editor-audio-cover"
          @mouseover.stop="coverHover"
          @mouseleave="displayTools = false"
        ></div>
        <div
          v-if="displayTools === true"
          class="flux-editor-audio-tools text-center"
          @mouseover.stop="toolsHover"
        >
          <button
            :class="{ active: displayProperties }"
            class="btn btn-simple btn-icon"
            @click.prevent="displayProperties = !displayProperties"
          >
            <fa :icon="['fas', 'cogs']" />
          </button>
          <button
            class="btn btn-simple btn-icon"
            @dblclick.prevent="$parent.deleteBlock(block.id)"
          >
            <fa :icon="['fas', 'trash']" />
          </button>
        </div>
        <audio
          controls
          :src="block.attributes.src"
          class="flux-editor-audio-player"
        >
          <p>
            Votre navigateur ne prend pas en charge l'audio HTML. Voici un un
            <a :href="block.attributes.src">lien vers le fichier audio</a> pour
            le télécharger.
          </p>
        </audio>
      </div>
      <figcaption
        v-if="block.attributes.title || block.attributes.credentials"
        :class="block.attributes.credentials_align"
      >
        <span v-if="block.attributes.title">
          {{ block.attributes.title }}
        </span>
        <span v-if="block.attributes.credentials">
          <br />
          {{ block.attributes.credentials }}
        </span>
      </figcaption>
    </figure>
    <el-form
      v-if="displayProperties === true"
      ref="form"
      size="small"
      :model="form"
      label-width="120px"
    >
      <el-form-item label="Légende" prop="title">
        <el-input v-model="form.title" @input="updateAttributes()" />
      </el-form-item>
      <el-form-item label="Crédits" prop="credentials">
        <el-input
          v-model="form.credentials"
          type="textarea"
          @input="updateAttributes()"
        />
      </el-form-item>
      <el-form-item label="Alignement">
        <el-radio-group
          v-model="form.credentials_align"
          @change="updateAttributes()"
        >
          <el-radio-button v-for="item in aligns" :key="item" :label="item">
            {{ $t(item) }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div v-if="block.attributes && !form.src">
      <h2 class="h4">Choisissez une source audio</h2>
      <div class="row">
        <div
          v-for="file in audioFiles"
          :key="file.id"
          class="col-sm-4 text-center"
        >
          <a href @click.prevent="setFile(file)">
            <fa :icon="['fas', 'file-audio']" class="fa-4x" />
            <br />
            {{ file.meta.filename }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  name: 'FluxEditorImage',
  props: {
    block: {
      type: Object,
      default() {
        return {}
      },
    },
    attachments: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      displayTools: false,
      displayProperties: false,
      aligns: ['text-left', 'text-center', 'text-right'],
      form: {
        src: null,
        title: null,
        credentials: null,
        credentials_align: null,
      },
    }
  },
  computed: {
    audioFiles() {
      const results = []
      for (const key in this.attachments) {
        if (
          this.attachments[key].meta &&
          this.attachments[key].meta.type &&
          this.attachments[key].meta.type === 'audio'
        ) {
          results.push(this.attachments[key])
        }
      }
      return results
    },
  },
  created() {
    this.form = JSON.parse(JSON.stringify(this.block.attributes))
  },
  methods: {
    setFile(file) {
      this.$parent.updateBlockAttributes(this.block.id, {
        src: file.static_url,
      })
      this.form.src = file.static_url
      this.$forceUpdate()
    },
    coverHover() {
      this.displayTools = true
    },
    toolsHover(event) {
      event.stopPropagation()
      this.displayTools = true
    },
    updateAttributes() {
      this.$parent.updateBlockAttributes(this.block.id, this.form)
    },
  },
}
</script>
<style scoped>
.flux-editor-audio-container {
  position: relative;
}

.flux-editor-audio-container:hover .flux-editor-audio-cover {
  background-color: black;
  opacity: 0.2;
}
.flux-editor-audio-cover {
  z-index: 1;
  position: absolute;
  /*background-color: red;*/
  opacity: 0;
  width: 100%;
  height: 100%;
}
.flux-editor-audio-tools {
  z-index: 2;
  position: absolute;
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 25px;
  background-color: white;
  padding: 0 10px;
  border: 1px solid black;
  border-radius: 5px;
}
.flux-editor-audio-player {
  width: 100%;
}
</style>
