import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import VueCompositionAPI from '@vue/composition-api'
import VueCurrencyFilter from 'vue-currency-filter'
import VueClipboard from 'vue-clipboard2'
import Pagination from 'vue-pagination-2'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/fr'

// import VueDayjs from 'vue-dayjs-plugin'
import editor from 'vue2-medium-editor'
import dayjs from 'dayjs'
import fr from 'dayjs/locale/fr'
import relativeTime from 'dayjs/plugin/relativeTime'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import isBetween from 'dayjs/plugin/isBetween'
import Dayjs from 'vue-dayjs'
import MarkdownItVue from 'markdown-it-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faClipboardList,
  faDatabase,
  faFileAudio,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faMinus,
  faPencilAlt,
  faPlus,
  faThumbtack,
  faTrash,
  faUnlink,
  faUser,
  faFilePdf,
  faFileCsv,
  faFileAlt,
  faFillDrip,
  faFolderOpen,
  faNewspaper,
  faParagraph,
  faImage,
  faImages,
  faVideo,
  faVolumeUp,
  faGripLines,
  faCode,
  faTv,
  faSync,
  faSearch,
  faCalendarWeek,
  faFlag,
  faCogs,
  faCashRegister,
  faCubes,
  faStar,
} from '@fortawesome/free-solid-svg-icons'
import FluxEditor from '@/components/FluxEditor/FluxEditor.vue'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

import 'element-ui/lib/theme-chalk/index.css'
import 'medium-editor/dist/css/medium-editor.css'
import 'medium-editor/dist/css/themes/tim.css'

Vue.use(ElementUI, { locale })

// icons
library.add(faClipboardList)
library.add(faDatabase)
library.add(faExternalLinkAlt)
library.add(faEye)
library.add(faEyeSlash)
library.add(faMinus)
library.add(faPencilAlt)
library.add(faPlus)
library.add(faThumbtack)
library.add(faTrash)
library.add(faUnlink)
library.add(faUser)
library.add(faFilePdf)
library.add(faFileAudio)
library.add(faFileCsv)
library.add(faFileAlt)
library.add(faFillDrip)
library.add(faFolderOpen)
library.add(faNewspaper)
library.add(faTv)
library.add(faParagraph)
library.add(faImage)
library.add(faImages)
library.add(faVideo)
library.add(faVolumeUp)
library.add(faGripLines)
library.add(faCode)
library.add(faSync)
library.add(faSearch)
library.add(faCalendarWeek)
library.add(faFlag)
library.add(faCogs)
library.add(faCashRegister)
library.add(faCubes)
library.add(faStar)
//  usage <font-awesome-icon icon="eye" />

Vue.component('font-awesome-icon', FontAwesomeIcon)

// BSV Plugin Registration
Vue.use(VueClipboard)
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueCurrencyFilter, {
  symbol: '€',
  thousandsSeparator: ' ',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'back',
  symbolSpacing: true,
  avoidEmptyDecimals: '00',
})
Vue.component('pagination', Pagination)
// Vue.use(VueDayjs)
dayjs.locale('fr')
dayjs.extend(relativeTime)
dayjs.extend(weekOfYear)
dayjs.extend(isBetween)
Vue.use(Dayjs, { lang: fr })
Vue.use(MarkdownItVue)
Vue.component('MediumEditor', editor)
Vue.component('FluxEditor', FluxEditor)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
