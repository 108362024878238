<template>
  <div class="flux-editor-image">
    <figure v-if="block.attributes && block.attributes.src">
      <div class="flux-editor-image-container">
        <div
          class="flux-editor-image-cover"
          @mouseenter.stop="coverHover"
          @mouseleave="displayTools = false"
        ></div>
        <div
          v-if="displayTools === true"
          class="flux-editor-image-tools text-center"
          @mouseenter.stop="toolsHover"
        >
          <button
            :class="{ active: displayProperties }"
            class="btn btn-simple btn-icon"
            @click.prevent="displayProperties = !displayProperties"
          >
            <font-awesome-icon icon="cogs" />
          </button>
          <button
            class="btn btn-simple btn-icon"
            @click.prevent="$parent.deleteBlock(block.id)"
          >
            <font-awesome-icon icon="trash" />
          </button>
        </div>
        <div
          :class="{
            'embed-responsive embed-responsive-4by3':
              block.attributes.aspect === '4/3',
            'embed-responsive embed-responsive-16by9':
              block.attributes.aspect === '16/9',
            'embed-responsive embed-responsive-1by1':
              block.attributes.aspect === '1/1',
            'embed-responsive embed-responsive-21by9':
              block.attributes.aspect === '21/9',
            'img-fluid': block.attributes.aspect === 'fluid',
          }"
        >
          <img
            :src="block.attributes.src"
            :class="{
              'img-fluid': block.attributes.aspect === 'fluid',
              'embed-responsive-item': block.attributes.aspect !== 'fluid',
            }"
            :style="`object-fit: ${block.attributes.fit}`"
          />
        </div>
      </div>
      <figcaption
        v-if="block.attributes.title || block.attributes.credentials"
        :class="block.attributes.credentials_align"
      >
        <span v-if="block.attributes.title">
          {{ block.attributes.title }}
        </span>
        <span v-if="block.attributes.credentials">
          <br />
          {{ block.attributes.credentials }}
        </span>
      </figcaption>
    </figure>
    <el-form
      v-if="displayProperties === true"
      ref="form"
      size="small"
      :model="form"
      label-width="120px"
    >
      <el-form-item label="Aspect">
        <el-radio-group v-model="form.aspect" @change="updateAttributes()">
          <el-radio-button v-for="item in aspects" :key="item" :label="item">
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Cadrage">
        <el-radio-group v-model="form.fit" @change="updateAttributes()">
          <el-radio-button v-for="item in fits" :key="item" :label="item">
            {{ $t(`FLUXEDITOR.${item.toUpperCase()}`) }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Lien" prop="href">
        <el-input v-model="form.href" @input="updateAttributes()" />
      </el-form-item>
      <el-form-item label="Cible">
        <el-radio-group v-model="form.href_target" @change="updateAttributes()">
          <el-radio-button v-for="item in targets" :key="item" :label="item">
            {{ $t(`FLUXEDITOR.${item.toUpperCase()}`) }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Légende" prop="title">
        <el-input v-model="form.title" @input="updateAttributes()" />
      </el-form-item>
      <el-form-item label="Crédits" prop="credentials">
        <el-input
          v-model="form.credentials"
          type="textarea"
          @input="updateAttributes()"
        />
      </el-form-item>
      <el-form-item label="Alignement">
        <el-radio-group
          v-model="form.credentials_align"
          @change="updateAttributes()"
        >
          <el-radio-button v-for="item in aligns" :key="item" :label="item">
            {{ $t(`FLUXEDITOR.${item.toUpperCase()}`) }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div v-if="block.attributes && !block.attributes.src">
      <h2 class="h4">Choisissez une image</h2>
      <div class="row">
        <div v-for="image in images" :key="image.id" class="col-sm-4">
          <a href @click.prevent="setImage(image)">
            <img :src="image.static_url" class="img-fluid" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  name: 'FluxEditorImage',
  props: {
    block: {
      type: Object,
      default() {
        return {}
      },
    },
    attachments: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      displayTools: false,
      displayProperties: false,
      aspects: ['1/1', '4/3', '16/9', '21/9', 'fluid'],
      fits: ['cover', 'contain'],
      aligns: ['text-left', 'text-center', 'text-right'],
      targets: ['_self', '_blank'],
      form: {
        src: null,
        href: null,
        href_target: '_self',
        aspect: null,
        fit: null,
        title: null,
        credentials: null,
        credentials_align: null,
      },
    }
  },
  computed: {
    images() {
      const results = []
      for (const key in this.attachments) {
        console.log(this.attachments[key])
        if (
          this.attachments[key].meta &&
          this.attachments[key].meta.type &&
          this.attachments[key].meta.type === 'image'
        ) {
          results.push(this.attachments[key])
        }
      }
      return results
    },
  },
  created() {
    this.form = JSON.parse(JSON.stringify(this.block.attributes))
  },
  methods: {
    setImage(image) {
      this.$parent.updateBlockAttributes(this.block.id, {
        src: image.static_url,
      })
      this.form.src = image.static_url
    },
    coverHover() {
      this.displayTools = true
    },
    toolsHover(event) {
      event.stopPropagation()
      this.displayTools = true
    },
    updateAttributes() {
      this.$parent.updateBlockAttributes(this.block.id, this.form)
    },
  },
}
</script>
<style scoped>
.flux-editor-image-container {
  position: relative;
}

.flux-editor-image-container:hover .flux-editor-image-cover {
  background-color: black;
  opacity: 0.2;
}
.flux-editor-image-cover {
  z-index: 1;
  position: absolute;
  /*background-color: red;*/
  opacity: 0;
  width: 100%;
  height: 100%;
}
.flux-editor-image-tools {
  z-index: 2;
  position: absolute;
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 25px;
  background-color: white;
  padding: 0 10px;
  border: 1px solid black;
  border-radius: 5px;
}
</style>
